<template>
  <div
    id="app"
    class="bg-blue-100 dark:bg-black"
  >
    <TheHeader />

    <main>
      <slot />
    </main>
    <TheFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TheHeader from '#components/layout/TheHeader.vue'
import TheFooter from '#components/layout/TheFooter.vue'

export default defineComponent({
  components: { TheHeader, TheFooter },
  head() {
    return {
      titleTemplate: 'Benjamin Scharf - Portfolio',
      meta: [
        {
          hid: 'portfolio',
          name: 'portfolio',
          content: 'Where you can find all the event taking',
        },
      ],
    }
  },
})
</script>
