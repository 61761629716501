<script setup lang="ts"></script>

<template>
  <div class="flex">
    <div
      class="grid grid-cols-2 items-center gap-4 text-center text-sm font-normal text-neutral-950 dark:text-neutral-500 lg:grid-cols-[repeat(3,max-content)]"
    >
      <span class="col-span-2 line-clamp-1 text-nowrap lg:col-span-1">
        © {{ new Date().getFullYear() }} All rights reserved
      </span>
      <NuxtLink
        class="hover-underline"
        to="/privacy"
      >
        <span class="line-clamp-1">Privacy Policy</span>
      </NuxtLink>
      <NuxtLink
        class="hover-underline"
        to="/cookie"
      >
        <span class="line-clamp-1"> Cookie Policy </span>
      </NuxtLink>
    </div>
  </div>
</template>
