<template>
  <aside
    ref="mobileContainer"
    class="fixed left-0 top-0 z-30 flex h-full w-full flex-col p-8 pt-36 backdrop-blur-lg dark:bg-[rgba(0,0,0,0.4)]"
  >
    <PublicNavbar
      class="mb-8 flex justify-center"
      show-icons
      direction="column"
      @click="$emit('handle:nav-click')"
    />

    <div class="flex items-center justify-between">
      <ThemeToggle />

      <div class="flex">
        <BaseLanguageSelector />
      </div>

      <BaseButton :to="{ name: 'auth-index-login' }">
        {{ $t('Login') }}
      </BaseButton>
    </div>

    <SocialBar
      class="mt-auto"
      :social-icons="socialLinks"
      size="normal"
    />

    <div
      class="flex flex-col items-center gap-4"
      @click="$emit('handle:nav-click')"
    >
      <BasePolicies class="flex-col" />
    </div>
  </aside>
</template>

<script lang="ts" setup>
import { socialLinks } from '#/domains/base/config/links'

import PublicNavbar from '#components/navigation/PublicNavbar.vue'
import ThemeToggle from '#components/library/action/ThemeToggle.vue'
import SocialBar from '#components/bars/SocialBar.vue'

defineEmits<{
  'handle:nav-click': []
}>()

onMounted(() => {
  document.querySelector('body')?.classList.add('overflow-hidden')
})

onBeforeUnmount(() => {
  document.querySelector('body')?.classList.remove('overflow-hidden')
})
</script>
