<template>
  <header
    ref="target"
    class="relative z-40 mx-auto flex max-w-[98%] translate-y-4 items-center rounded-full border border-secondary-200 p-3 transition-all dark:border-secondary-950"
    :class="{
      'sticky -top-4  translate-y-0 scale-[0.8] backdrop-blur-sm':
        !isOutOfViewport,
    }"
  >
    <NuxtLink
      to="/"
      class="mr-16 flex cursor-pointer"
    >
      <BaseLogo />
    </NuxtLink>

    <PublicNavbar class="mr-auto hidden md:flex" />

    <Teleport to="body">
      <Transition name="roleInRight">
        <MobileNavBar
          v-if="state.showNavMenu"
          @handle:nav-click="toggleNav"
        />
      </Transition>
    </Teleport>

    <BaseLanguageSelector class="language-selector hidden lg:block" />

    <ClientOnly>
      <ThemeToggle class="invisible mr-8 lg:visible" />
    </ClientOnly>

    <BaseHamburger />

    <BaseButton
      ref="navBtn"
      class="hidden lg:block"
      :to="{ name: 'auth-index-login' }"
    >
      {{ $t('Login') }}
    </BaseButton>
  </header>
</template>

<script setup lang="ts">
// ************* import UTILS & HELPERS ************* //
import { watch } from 'vue'
// ************* import COMPOSABLES ************* //
import PublicNavbar from '#components/navigation/PublicNavbar.vue'
import MobileNavBar from '#components/navigation/MobileNavBar.vue'
import BaseHamburger from '#components/library/buttons/BaseHamburger.vue'
import ThemeToggle from '#components/library/action/ThemeToggle.vue'
// ************* import STORES ************* /
import { useAppStore } from '#/stores/appStore'
// ************* STORES ************* //
const { state, toggleMobileNav } = useAppStore()

function toggleNav() {
  toggleMobileNav(false)
}

const isOutOfViewport = useState<boolean>('apply-sticky-effect')

watch(
  () => state.showNavMenu,
  (val) => {
    val
      ? document.body.classList.add('freeze')
      : document.body.classList.remove('freeze')
  },
)
</script>

<style scoped></style>
