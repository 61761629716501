import { v4 as createUid } from 'uuid'

export const publicNavigation = [
  {
    label: 'Home',
    link: '/#home',
    icon: 'i-heroicons-home',
    id: createUid(),
  },
  {
    label: 'Projects',
    link: '/#projects',
    icon: 'i-heroicons-building-library',
    id: createUid(),
  },
  {
    label: 'Blog',
    link: '/blog',
    icon: 'i-heroicons-newspaper',
    id: createUid(),
  },
  {
    label: 'Contact',
    link: '/#contact',
    icon: 'i-heroicons-envelope',
    id: createUid(),
  },
]

export const socialLinks = [
  {
    link: 'https://twitter.com/v_ben_tec',
    icon: 'i-bxl-twitter',
  },
  {
    link: 'https://github.com/vBenTec',
    icon: 'i-bxl-github',
  },
  {
    link: 'https://www.linkedin.com/in/benjamin-scharf-29a549216/',
    icon: 'i-bxl-linkedin',
  },
]
